import { takeLatest, call, put } from "redux-saga/effects";
import {
  userSignUpSuccess,
  userSignUpError,
  userSignUpDuplicateEmail,
  USER_SIGNUP_REQUEST,
} from "../actions/signup";
import recordsApi from "../api/records";
import API_CODES from "../config/api-codes";

function userSignUp() {
  return function* (actions) {
    try {
      const { payload } = actions;

      let _payload = {
        "query": [
          {
            "z1": "1"
          }
        ],      
        "script" : "createPatient",
        "script.param" : `${payload.nameFirst}|${payload.nameLast}|${payload.email}|${payload.phoneMobile}|${payload.address1}|${payload.address2}|${payload.addressTown}|${payload.addressCounty}|${payload.addressPostcode}|${payload.country}|${payload.password}|${payload.dateOfBirth}`
      }

      const data = yield call(() => recordsApi.userSignUp(_payload));
      if(
        data &&
        data['response'] &&
        data['response']['scriptResult']  === "This email is already in use."
      ) {
        yield put(userSignUpDuplicateEmail(data));
      }
      
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(userSignUpSuccess(data));
      } else {
        yield put(userSignUpError(data));
      }
    } catch (error) {
      if(
        error &&
        error['data']['response'] &&
        error['data']['response'] &&
        error['data']['response']['data'] &&
        error['data']['response']['data']['messages'][0] &&
        error['data']['response']['data']['messages'][0].code === "1"
      ){
        yield put(userSignUpError(error));
      }
    }
  };
}

export function* userSignupWatcher() {
  yield takeLatest(USER_SIGNUP_REQUEST, userSignUp());
}

export default [userSignupWatcher()];
